import { render, staticRenderFns } from "./KanbanColumn.vue?vue&type=template&id=baf82cc8&scoped=true&"
import script from "./KanbanColumn.vue?vue&type=script&lang=js&"
export * from "./KanbanColumn.vue?vue&type=script&lang=js&"
import style0 from "./KanbanColumn.vue?vue&type=style&index=0&id=baf82cc8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baf82cc8",
  null
  
)

export default component.exports