var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-2 cursor-pointer",staticStyle:{"max-width":"350px"}},[_c('v-app-bar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h6 pl-0"},[(_vm.element.bookingItem)?_c('div',[_vm._v(" "+_vm._s(_vm.element.bookingItem.name || "Unbekannt")+" ")]):_vm._e()]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.onOpenBooking(_vm.element.bookingItem.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-list-item-title',[_vm._v("Buchungsdetails anzeigen")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.onOpenEditBooking(_vm.element.bookingItem.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Buchung bearbeiten")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.commitBooking(_vm.element.bookingItem.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1),_c('v-list-item-title',[_vm._v("Buchung freigeben")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.archiveTask(_vm.element.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-archive")])],1),_c('v-list-item-title',[_vm._v("Buchung archivieren")])],1),(!_vm.backlog)?_c('v-list-item',{on:{"click":function($event){return _vm.moveTask(
              { added: { element: { id: _vm.element.id }, newIndex: 0 } },
              'backlog'
            )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-database")])],1),_c('v-list-item-title',[_vm._v("In Backlog verschieben")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.rejectBooking(_vm.element.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-list-item-title',[_vm._v("Buchung stornieren")])],1)],1)],1)],1),(_vm.element.bookingItem)?_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.element.bookingItem.bookableItems[0]?._bookableUsed?.title)+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" Buchungsnummer: "+_vm._s(_vm.element.bookingItem?.id)+" ")])],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!_vm.backlog)?_c('v-rating',{staticClass:"mr-4",attrs:{"empty-icon":"mdi-circle-outline","full-icon":"mdi-circle","readonly":"","dense":"","length":"4","size":"10","background-color":"purple lighten-3","color":_vm.durationInStatus(_vm.element.added) >= 4
                ? 'error'
                : _vm.durationInStatus(_vm.element.added) >= 2
                ? 'warning'
                : 'success',"value":_vm.durationInStatus(_vm.element.added)}}):_vm._e()],1)]}}])},[_c('span',[_vm._v("Die Buchung befindet sich seit "+_vm._s(_vm.durationInStatus(_vm.element.added))+" "+_vm._s(_vm.durationInStatus(_vm.element.added) === 1 ? "Tag" : "Tagen")+" in diesem Status ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }